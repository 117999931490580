.border{
    cursor: pointer;
    border: 2px solid $white;
    border-radius: 150px;
    height: 40px;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;

    .player{
        
        width: 100%;
        height: 100%;
        transform: rotate(-90deg);
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        span{
            border-radius: 200px;
            margin-bottom: 3px;
            background: $white;
            max-height: 3px;
            height: 3px;
            display: block;
            width:3px;
        }

    }   
    .screenMode{
        margin-left: auto;
        height: 100%;
        background-color: $white;
        width: 50%;
        border: 1px solid $white;
        min-width: 40px;
        border-radius: 150px 150px 150px 150px;
        align-items: center;
        justify-content: center;
        display: flex;
        overflow: hidden;
        div{
            svg{
            position: relative;
            }
        }
        .iconSun{
            top: -40px;
            display: none;
        }
    }:nth-child(1){
        color: $black;
    }
}