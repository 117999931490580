.canvas1{
    position: absolute!important;
}
.card-question-2{
    margin: 0 auto;
    width: 40%;
    text-align: center;
    color: $white;
    padding-top: 30vh;
    


    p{
        z-index: 99;
        position: relative;
        pointer-events: none;
    }

    .btn-list{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        
        a{
            text-decoration: none;
            color: $white;
        }
    .btn-question-2{
        position: relative;
        margin-left: 15px;
        margin-right: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        min-width: 100px;
        min-height: 100px;
        border:  1px solid $white;
        border-radius: 500px;
}
    }
}