.card-question{
    text-align: center;
    color: $white;
    padding-top: 20vh;
    .icon-question{

    }
    .question-btn{
        display: flex;
        justify-content: center;
}
}