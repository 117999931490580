.btn{
    display: flex;
    position: relative;
    height: 250px;
    width: 250px;
    justify-content: center;
    align-items: center;
    cursor: none;
    color: $white;
    text-decoration: none;
  
    &:hover .btnSticky{
      -webkit-transition: background-color 300ms ease-in-out;
      -ms-transition: background-color 300ms ease-in-out;
      transition: background-color 300ms ease-in-out;
      background-color: $white;
      color: $black;
    }
    &:active .btnSticky{
      width: 97px;
      height: 97px;
    }
  
  
    .btnSticky{
      border: 1px solid $white;
      display: flex;
      justify-content: center;
      border-radius: 100%;
      height: 100px;
      width: 100px;
      pointer-events: none;
      cursor: none;
      align-items: center;
    }
  }