.header{
    // position: fixed;
    position: absolute;
    top: 0;
    width: 100%;
    height: 128px;
    z-index: 4;

   // background-color: linear-gradient(rgba(35, 35, 35,0), rgba(35, 35, 35,.5), transparent 100%);
    @include media("<=phone"){
       // background-color: linear-gradient(to bottom, $black 0%, $black 50%, transparent 100%);
    }
    .widget{
        width: 80px;
    }
    @include media("<=phone"){
        height: 96px;
    }
    .logo{
        a{
            font-size: 1.2rem;
            letter-spacing: 0rem;
            color: $white;
            text-decoration: none;
            span{
                font-weight: 400;
            }:nth-child(2){
                font-weight: 700;
            }
            
        }
    }
.nav-toggle{
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    @include media("<=tablet"){
        margin-right: 24px;
    }
    @include media("<=phone"){
        margin-right: 16px;
        width: 20px;
    }
    .hamburger-menu{
        width: 35px;
        span{
            margin-bottom: .3rem;
            background: $white;
            height: 3px;
            width: 100%;
            display: block;
        }:nth-child(2){
            width: 80%;
        }
    }
    .hamburger-menu-close{
        width: 35px;
        display:none;
        span{
            margin-bottom: .3rem;
            background: $black;
            height: 3px;
            width: 100%;
            display: block;
        }:nth-child(2){
            width: 80%;
            margin-left: auto;
        }
    }
}
}