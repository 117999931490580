.intro-overlay{
    .top{
        height: 100vh;
        height: calc(var(--vh, 1vh)*100);
        position: absolute;
        width: 100%;
        overflow: hidden;
        .overlay-top{
            position: relative;
            height: 100%;
            width: 100vw;
            background: $black;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 6;
            .loading-percentage{
                font-family:"font";
                color: $white;
                display: inline-block;
                font-size: 20vw;
                position: absolute;
                bottom: 0;
                margin-left: 160px;
                transform: translateY(0.33em);
                @include media("<=tablet"){
                    font-size: 30vw;
                }
            }
        }
    }
}