nav {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: none;
    position: fixed;
    overflow: hidden;
    background-color: $white;

    .nav-content{
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-template-rows: repeat(4, 1fr);
      height: 100vh;
      width: 100vw;

    .nav-links{
      @include media("<=phone") {
      grid-column: 1 / 6;
      }
      grid-column: 2 / 6;
      grid-row: 2 / 3;
      li{
        font-family: "font";
        list-style-type: none;
        font-size: 5rem;
        a{
          color: $black;
          text-decoration: none;
        }
      }

      &-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size:contain;
        background-repeat:no-repeat;
      }
    
      &-img-wrap {
        min-width: 50vw;
        height: 15vh;
        position: absolute;
        top: 0;
        left: 5%;
        overflow: hidden;
        pointer-events: none;
        visibility: hidden;
        padding-bottom: 25vw;
      }
    }
    .nav-footer{
      grid-column: 1 / 7;
      grid-row: 4 / 5;
      display: flex;
      flex-wrap: wrap;
      max-width: 100%;
     
      .nav-copyright{
        cursor: pointer;
        padding-left: 5%;
        padding-right: 5%;
        width: 15%;
        @include media("<=tablet"){
          width: 40%;
        }
        >a{
          font-weight: 800;
          white-space: nowrap;
          transition: text-decoration-color 300ms;
          text-decoration: underline 0.15em rgba(255, 255, 255, 0);
        } :hover{
          text-decoration: underline 0.15em  rgba(0, 0, 0, 1);
        }
        
      }
      .nav-footer-social{
        height: 100%;
        display: flex;
        width: 45%;
        padding-right: 20%;
        padding-left: 10%;
        @include media("<=tablet"){
          width: 90%;
          padding-right: 5%;
          padding-left: 5%;
        }
        justify-content: space-between;
        li{
          list-style-type: none;
          a{
          color: $black;
          text-decoration: none;
          }
        }
      }
    }
  }
    
  }