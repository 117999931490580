.page{
    .container-home{
        display: grid;
        height: 100%;
        grid-template-columns: repeat(2, 1fr);
        // grid-auto-rows: minmax(100px, auto);
        grid-gap: 10px;
        // height: 100vh;

        .texte-pres{
            display: flex;
            grid-column: 1 / 2;
            grid-row: 1 / 2; 
            text-align: left;
            justify-content: center;
            // padding-top: 15vh;
            // margin-bottom: 100px;

            @include media("<=desktop") {
                padding-top: 6vh;
            }
            @include media("<=tablet"){
                margin: 0 auto;
                padding-top: 8vh;
            }
            @include media("<=phone"){
                padding-top: 4vh;
            }

            .line{
                margin: auto 0;
            }
            
            .word{
                opacity: 0;
            }
          
            .react-split-text {
                font-family:"font";
                font-weight: 100;
                color: $white;
                // line-height: 2rem;
                font-size: 2em;
                @include media("<=desktop") {
                    font-size: 1.6rem;
                    // line-height: 3.2rem;
                }
                @include media("<=tablet"){
                    font-size: 1.3rem;
                }
                @include media("<=phone"){
                    font-size: 1.5rem;
                    line-height: 1.7rem;
                }
 
            }
            @include media("<=tablet"){
                grid-column: 1 / 3;
                grid-row: 1 / 2; 
            }
        }
        .photo-pres{
            display: grid;
            grid-column: 2 / 3;
            grid-row: 1 / 2;
            display: flex;
            justify-content: center;
            position: relative;

    
            @include media("<=tablet"){
                grid-column: 1 / 3;
                grid-row: 2 / 3; 
            }
            .motion{
                display: flex;
            justify-content: center;
            position: relative;
            .player-wrapper{
            align-items: center;
               height: 100%;
               display: flex;
               width: 50%;
               @include media("<=tablet"){
                width: 100%;
            }
               video{
                    border-radius: 500px 500px 20px 20px;
                    height: 80%;
               }
            }
        }

        .motion-btn{
            z-index: 99;
            a{
                margin: 0 auto;
                vertical-align: middle;
                display: flex;
                text-align: center;
                position: absolute;
                z-index: 2;
                left: 7%;
                bottom: 0;
                
            .btn-question{
                margin: 0 auto;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

            
        }
        .footer{
            display: flex;
            align-items: flex-end;
            @include media("<=tablet"){
                display:initial;
            }
           
            grid-column: 1 / 3;
            grid-row: 2 / 3;
            // height: 25vh;
            vertical-align: middle;
            line-height: 2em;
            font-family:"font";
            font-weight: 100;
            color: $white;
            font-size: 2em;
            position: relative;

            &:before{
                
                content: "";
                position: absolute;
                display: block;
                left: -10px;
                width: 15%;
                height: 100%;
                top: 0;
                z-index: 2;
                background: linear-gradient(to right, $black 0%, transparent 100%);
            }
            &:after{
                content: "";
                position: absolute;
                display: block;
                right: -10px;
                width: 15%;
                height: 100%;
                top: 0;
                z-index: 2;
                background: linear-gradient(to left, $black 0%, transparent 100%);
            }

            @include media("<=tablet"){
                grid-column: 1 / 3;
                grid-row: 3 / 4; 
            }


            //FOOTER SLIDIIIIING
            .marquee {
                border-top: 2px solid $white;
                margin: 0 auto;
                white-space: nowrap;
                overflow: hidden;
                position: absolute;
                width: 100%;
              }
              .marquee a{
                  color: $white;
                  text-decoration: none;
              }
              
              .marquee span {
                display: inline-block;
                padding-left: 1300px;
                animation: marquee 20s linear infinite;
              }
              
              .marquee2 span {
                animation-delay: 10s;
              }
              
              @keyframes marquee {
                0% {
                  transform: translate(0, 0);
                }
                100% {
                  transform: translate(-100%, 0);
                }
              }
        }
    }
    }