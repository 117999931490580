body::-webkit-scrollbar {
  display: none;
}


.projet{
  //height: 100vh;
  width: 100%;
  background: $black;
  padding-top: 96px;
  //I fucking don't know why I need to do that but it works
  @include media("<=phone"){
    margin-top: -46px;
}

overflow: hidden;
  .photo-cover{
    display: flex;
    flex-direction: row;
    height: 80vh;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    .photo-container{
      display: flex;
      height: 400px;
      width: 350px;
      position: relative;

    .photo{
      display: flex;
      height: 400px;
      width: 350px;

    }
    img{
      object-fit: cover;
      height: 400px;
      width: 350px;
      
    }
    .titre{
      top: 25%;
      width: 80%;
      position: absolute;
      
    h2{
      top: -30px;
      position: relative;
      font-family:"font";
      font-weight: 100;
      line-height: 3.5rem;
      color: $white;
      font-size: 4rem;
    }
  }
}
  }
.presentation{
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-auto-rows: minmax(100px, auto);
  grid-gap: 10px;
  margin-top: -8%;
  margin-bottom: 150px;
  .texte-pres{

    grid-column:  2/ 5; 
    text-align: left;
    justify-content: center;
    @include media("<=tablet"){
      grid-column:  1 / 6;
    }

    @include media("<=tablet"){
        margin-left: 20px;
        margin-right: 20px;
    }
    .word{
        opacity: 0;
        font-family:"font";
        font-weight: 100;
        color: $white;
        line-height: 2.7rem;
        font-size: 2rem;
        @include media("<=tablet"){
            font-size: 1.2rem;
            line-height: 1.7rem;
        }
    }
    
  }
}



.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  width: auto;
  transition: transform 0.25s;
	will-change: transform;
}
.my-masonry-grid_column {
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div > img { /* change div to reference your elements you put in <Masonry> */
  width: 100%;
  margin-top: -4px;
}

.footer-pres{
  position: absolute;
  bottom: 0;
  width: 80%;
  height: 300px;
  display: flex;
  left: 0; 
  right: 0; 
  margin-left: auto; 
  margin-right: auto; 
  justify-content: space-between;

  .btn{
    width: auto;
  }
  
  a{
    display: flex;
    align-self: center;
}
}
}