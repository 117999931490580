.transition{
  height: 100vh;
  width: 100%;
  background: $black;
  padding-top: 96px;
  margin-top: -96px;
  overflow: hidden;


.gallery-item {
    z-index: 1;  
    height: 100%;
    width: 75%;
  
    .gallery-item-image {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;
      will-change: transform;
      clip-path: inset(0% 100% 0% 0%);
      transition: clip-path 1s cubic-bezier(0.77, 0, 0.175, 1);
      &.reveal {
        clip-path: inset(0% 0% 0% 0%);
        .gallery__item-imginner {
          transform: scale(1);
        }
      }
    }
    .gallery__item-imginner {
      background-size: cover;
      background-position: center;
      transform-origin: center left;
      width: 100%;
      height: 100%;
      will-change: transform;
      transform: scale(1.1);
      transition: transform 2s cubic-bezier(0.77, 0, 0.175, 1);
    }
  }

  .main-container {
    padding-right: 500px;
    box-sizing: border-box;
  }
  
  .c-fixed_target {
    position: absolute;
    top: -100vh;
    right: 0;
    left: 0;
    bottom: -100vh;
  }

  .scroll-container {
    margin-right: 500px;
  }
  
  .content {
    margin-top: 20vh;
    height: 70vh;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
  }
  
  .gallery {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
    min-width: 1800px;
    width: 250vw;
    padding-left: 10vw;
    height: 100%;
    position: relative;
    align-items: center;
  }

  .gallery-helper {
    /*grid-area: 43/1 / span 2 / span 1;
    font-size: 20px;*/
  }


  .rotate{
    
 
  //GALLERY V2
.image-container{
  cursor:grab;
  width: 200vw;
  height: 100%;
  overflow: hidden;
  position: relative;
  transform: translateX(0);
  margin-top: 96px;
  @include media("<=phone"){
    margin-top: 50px;
}

  .scrollable{
    display: inline-flex;
    flex-direction: row;
    height: 80vh;
    justify-content: space-around;
    align-items: center;

    
    .photo-container{
      margin-left: 40px;
      margin-right: 40px;
      display: flex;
      height: 400px;
      width: 350px;
      position: relative;
      
      img{
        pointer-events: none;
        will-change: transform;

      }
      .photo{
        height: 100%;
        width: 100%;
        object-fit: cover;
      }      

      .photo-texte{
        top: 25%;
        position: absolute;
        width: 80%;
        h2{
          position: relative;
          font-family:"font";
          font-weight: 100;
          line-height: 3.5rem;
          color: $white;
          font-size: 4rem;
        }
        h3{
          margin-top: -30px;
          position: relative;
          color: $white;
          font-weight: 100;
          font-size: 2rem;
          width: 50%;
          border-bottom: 1px solid currentColor;
        }
      }
    }
}
}
  }
.progress-bar-container{
  width: 50vw;
  margin: 0 auto;
  position: relative;
  .progress-bar{
    height: 6px;
    border-radius: 3px;
    background-color: $white;
    position: absolute;
    bottom: 0px;
  }
  ::before{
    content: "";
    opacity: 0.15;
    height: 6px;
    border-radius: 3px;
    background-color: $white;
    position: absolute;
    bottom: 0px;
    width: 50vw;
  }
}
}