.cases{
    .case{
        .case-image{
            text-align: center;
            position: relative;
            img{
                width: 90%;
                height: 25vh;
                border-radius: 20px;
                object-fit: cover;
                position: relative;
                z-index: 1;
            }
            .backgroundImg{
             position: absolute;
			top: 2px;
			left: 5%;
            opacity: .5;
			transform: translate(0, 10px);
            transform: scale(1.015);
			z-index: 1;
            filter: blur(10px);
            z-index: 0;
            }
            
        }
        .case-details{
            text-align: left;
            margin-left: 5%;
            margin-top: 10px;
            margin-bottom: 15px;
        }
    }
}