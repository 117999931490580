
.bg-overlay{
    position: relative;
    background: $black;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh)*100);
    
    .bg-image{
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        opacity: .65;
        transition: .4s cubic-bezier(0.6,-0.05,0.1,0.99);
        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
            z-index: 1;
        }
    }
    .details{
        left: 50%;
        transform: translate(-50%, 0);
        position: fixed;
        border-radius: 20px;
        bottom: 150px;
        padding-left: 32px;
        padding-right: 32px;
        width: 70%;
        box-shadow: 0 0 .1rem 0 rgba(0, 0, 0, .1); 
        background-color: rgba(255, 255, 255, .15);
        backdrop-filter: blur(8px);
        h1{
            opacity: 1;
            color: white;
            text-align: left;
        }
        p{
            opacity: 1;
            color: white;
            text-align: left;   
        }
    }
}