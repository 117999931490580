@import url("https://fonts.googleapis.com/css?family=Josefin+Sans:400,600,700&display=swap");
@import "./variables.scss";
@import "./breakpoints.scss";
@import "./components/header.scss";
@import "./components/introOverlay.scss";
@import "./components/navigation.scss";
@import "./components/article.scss";
@import "./components/about.scss";
@import "./components/widget.scss";
@import "./components/home.scss";
@import "./components/question.scss";
@import "./components/mood.scss";
@import "./components/galleryItems.scss";
@import "./components/projet.scss";
@import "./components/button.scss";


@font-face {
  font-family: 'font';
  src: url('../assets/font.woff2') format('woff2'),
  url('../assets/font.woff') format('woff');
}

body,
html{
  visibility: hidden;
  user-select: none;
  background: $black;
  overscroll-behavior: none;
  margin: 0;
  font-family:"Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  // overflow: hidden;
}

main{
  height: 100vh;
}

.container {
  width: 1560px;
  min-width: 1560px;
  margin: 0 auto;
  height: 100%;
  @include media("<=1560px") {
    width: 1280px;
    min-width: 1280px;
  }
  @include media("<=desktop") {
    width: 1080px;
    min-width: 1080px;
  }
  @include media("<=1080px") {
    width: 85%;
    min-width: 85%;
  }
  .row {
    display: flex;
    height: 100%;
    // padding: 0 32px;
    @include media("<=phone") {
      padding: 0 16px;
    }
  }
  .v-center {
    align-items: center;
  }
  .space-between {
    justify-content: space-between;
  }
}

.container-fluid {
  width: 100%;
  height: 100%;
  .row {
    display: flex;
    height: 100%;
  }
}


.App{
  z-index: 2;
  transform: translateY(0);
  position: relative;
}

.page{
  height: 85vh;
  width: 100%;
  background: $black;
  padding-top: 15vh;
   .row{
     display: flex;
   }
}
